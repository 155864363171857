import React from "react";
import { SectionWithHeadingDesc } from "../layouts/sections";
import Image from "next/image";
import processImg from "../../assets/images/HomeProcess.svg";
import processImgLight from "../../assets/images/HomeProcessLight.svg";

const Index = ({ processData, dark = true }: any) => {
  const contactProcess = processData?.contactProcess;
  return (
    <SectionWithHeadingDesc
      ngClass="relative bg-no-repeat bg-cover flex items-center flex-col flex-wrap"
      textHeader={processData?.Text?.TextHeader}
      highlightText={processData?.Text?.highlightText}
      description={""}
      desStyle={dark ? "text-white" : "text-primary"}
      tHeadColor={dark ? "text-white" : "text-primary"}
    >
      <div className="lg:hidden mb-12 flex justify-center">
        <Image src={dark ? processImg : processImgLight} alt="process" />
      </div>

      <div className="flex lg:flex-row flex-col gap-[50px] items-center justify-center">
        <div className="flex flex-col gap-10 md:w-[632px]">
          {contactProcess?.map((item: any, index: number) => {
            return (
              <div
                className={`flex items-center ${
                  index === contactProcess.length - 1 ? "gap-4" : "gap-[34px]"
                }`}
                key={index}
              >
                <div className=" px-[18px] py-3 after:rounded-full gradient-border after:bg-dark-gradient after:top-[3px] after:bottom-[3px] after:left-[3px] after:right-[3px]  ">
                  <h4 className="text-gradient-btn text-center w-4">
                    {index + 1}
                  </h4>
                </div>
                <div
                  className={`flex flex-col gap-3 ${
                    dark ? "text-white" : "text-primary"
                  }  ${
                    index === contactProcess.length - 1
                      ? dark
                        ? "bg-white bg-opacity-[8%] rounded-3xl border border-white border-opacity-20 px-[18px] py-3"
                        : "bg-primary bg-opacity-[8%] rounded-3xl border border-primary border-opacity-20 px-[18px] py-3"
                      : null
                  }`}
                >
                  <h4> {item.title} </h4>
                  <p>{item.detail}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="hidden lg:block">
          <Image src={dark ? processImg : processImgLight} alt="process" />
        </div>
      </div>
    </SectionWithHeadingDesc>
  );
};

export default Index;
