import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import axios from 'axios';

import { BlankSection as staticBlankSection } from '../src/components/layouts/sections';
import { getBaseUrl } from '../src/util/serverUtils';

import HomeHero from '../src/components/animations/HomeHero';
import BgGlow from '../src/components/bgglow';
import ServiceProcess from '../src/components/ServiceProcess';
import HomeContactProcess from '../src/components/HomeProcess';
import QuoteSection from '../src/components/CTA';
import VGBG from '../src/components/vgbg';
import { fetchFeaturedProductData } from '../src/services/dynamicDataFetch';

import BgStarAnimation from '../src/components/BgStarAnimation';
import AnimationImage from '../src/components/animations/HomeHero';
import { strapiImageUrl } from '../src/services/generateImageUrl';

const MetaDecorator = dynamic(() => import('../src/core/meta/MetaDecorator'));
const HeroSection = dynamic(() => import('../src/components/herosection'));
const HomeClient = dynamic(() => import('../src/components/clientSection'));
const HomeService = dynamic(() => import('../src/components/HomeService'));
const Technology = dynamic(() => import('../src/components/OurTechnology'));
const ServiceTestimonial = dynamic(
    () => import('../src/components/ServiceTestimonial')
);
const FtWorkPortfolio = dynamic(
    () => import('../src/components/FtWorkPortfolio')
);
const FeaturedArticles = dynamic<any>(
    () => import('../src/components/featuredArticles')
);

const BlankSection = dynamic<React.ComponentProps<typeof staticBlankSection>>(
    () =>
        import('../src/components/layouts/sections').then(
            (mod) => mod.BlankSection
        )
);

const BlankSectionNoSpacing = dynamic<any>(() =>
    import('../src/components/layouts/sections').then(
        (mod) => mod.BlankSectionNoSpacing
    )
);

export default function Home({
    data,
    canonicalUrl,
}: {
    data: any;
    canonicalUrl: string;
}) {
    const [homeHeroData, setHomeHeroData] = useState<any>();
    const [homeClientData, setHomeClientData] = useState<any>();
    const [homeServiceData, setHomeServiceData] = useState<any>();
    const [homeTechData, setHomeTechData] = useState<any>();
    const [serviceProcessData, setServiceProcessData] = useState<any>();
    const [homeContactProcessData, setHomeContactProcessData] = useState<any>();
    const [homeCtaData, setHomeCtaData] = useState<any>();
    const [homeFeaturedArticle, setHomeFeaturedArticle] = useState<any>();
    const [featuredSectionData, setFeaturedSectionData] = useState<any>();
    const [featuredProducts, setFeaturedProducts] = useState<any>([]);

    const [isComponentLoaded, setIsComponentLoaded] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsComponentLoaded(true);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchFeaturedProductData();
            setFeaturedProducts(response.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setHomeHeroData(data?.attributes?.hero);
        setHomeClientData(data?.attributes?.ourClients);
        setHomeServiceData(data?.attributes?.ourService);
        setHomeTechData(data?.attributes?.techStack);
        setServiceProcessData(data?.attributes?.workingProcess);
        setHomeContactProcessData(data?.attributes?.contact_processes);
        setHomeCtaData(data?.attributes?.cta_single?.data?.attributes?.CTA);
        setHomeFeaturedArticle(
            data?.attributes?.featuredArticle?.data?.attributes
        );
        setFeaturedSectionData(data?.attributes?.featuredWork);
    }, [data]);

    return (
        <>
            <MetaDecorator
                title={
                    data?.attributes?.seo
                        ? data?.attributes?.seo?.metaTitle
                        : 'Virtual gravity | Home'
                }
                description={
                    data?.attributes?.seo
                        ? data?.attributes?.seo?.metaDescription
                        : 'Virtual Gravity is an IT company based in Melbourne, Australia, with a development team in Nepal. We offer high-quality and cost-effective web and mobile application development services to clients worldwide. Contact us today to learn how we can help your business succeed through technology.'
                }
                imageUrl={
                    data?.attributes?.seo
                        ? strapiImageUrl(
                              data?.seo?.media?.media?.data?.attributes?.url
                          )
                        : 'https://virtual-gravity.com/images/logo.svg'
                }
                imageAlt={
                    data?.attributes?.seo
                        ? data?.attributes?.seo?.media?.alt
                        : 'virtual-gravity-image'
                }
                keywords={
                    data?.attributes?.seo
                        ? data?.attributes?.seo?.keywords
                        : 'virtual-gravity'
                }
                canonicalUrl={canonicalUrl}
            />
            <BlankSection
                ngClass={
                    'relative bg-no-repeat bg-cover py-20 h-full flex items-center flex-col justify-center'
                }
            >
                <BgStarAnimation />
                <BgGlow />
                {homeHeroData && (
                    <HeroSection
                        headingText={homeHeroData?.TextHeader}
                        colorHeading={homeHeroData?.highlightText}
                        herosectionContent={homeHeroData?.Description}
                        animationImg={<AnimationImage />}
                        arrow={false}
                        rocket={false}
                        ngclass1="px-4 pt-12 lg:pt-20"
                        ngclass2="text-white lg:text-left text-center min-h-[450px]"
                        buttons={homeHeroData?.Buttons}
                    />
                )}
            </BlankSection>

            {homeClientData && (
                <HomeClient clientSectionData={homeClientData} />
            )}

            {homeServiceData && (
                <BlankSection ngClass="relative bg-no-repeat bg-cover justify-center h-full flex items-center flex-col overflow-hidden py-20">
                    <HomeService serviceSectionData={homeServiceData} />
                </BlankSection>
            )}

            {serviceProcessData && (
                <ServiceProcess
                    processData={serviceProcessData?.data?.attributes}
                />
            )}

            <div className="relative">
                <BgStarAnimation />

                {homeTechData && <Technology technologyData={homeTechData} />}

                <BlankSection ngClass="relative bg-no-repeat bg-cover h-full flex items-center flex-col">
                    <BgGlow />
                    <ServiceTestimonial />
                </BlankSection>
            </div>

            <BlankSection ngClass="relative bg-no-repeat bg-cover h-full flex items-center flex-col overflow-hidden bg-dark-gradient">
                {isComponentLoaded && (
                    <FtWorkPortfolio
                        featuredItem={featuredProducts}
                        featuredSectionData={featuredSectionData}
                        featuredType={'product'}
                    />
                )}
            </BlankSection>

            {homeContactProcessData && (
                <BlankSection ngClass="relative bg-no-repeat bg-cover flex-col h-full py-20 flex justify-center">
                    <VGBG />
                    <HomeContactProcess
                        processData={
                            homeContactProcessData?.data?.[0]?.attributes
                        }
                        dark={false}
                    />
                </BlankSection>
            )}

            {homeCtaData && (
                <BlankSectionNoSpacing ngClass={'relative'}>
                    <QuoteSection
                        ngClass=" after:bottom-[-16px]"
                        textHeader={homeCtaData?.TextHeader}
                        highlightText={homeCtaData?.highlightText}
                        description={homeCtaData?.Description}
                        button={homeCtaData?.Buttons[0]}
                    />
                </BlankSectionNoSpacing>
            )}

            {homeFeaturedArticle && (
                <FeaturedArticles featuredArticleData={homeFeaturedArticle} />
            )}
        </>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    //@ts-ignore
    const currentPath = getBaseUrl(context.req) + context.req.url;

    try {
        const result = await axios.get(
            `${process.env.admin_url}/api/home-page?populate=deep`
        );
        const data = result?.data?.data;

        return {
            props: {
                data,
                canonicalUrl: currentPath.includes('.json')
                    ? null
                    : currentPath,
            },
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        return {
            props: {
                data: null,
                canonicalUrl: currentPath.includes('.json')
                    ? null
                    : currentPath,
            },
        };
    }
};
